exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-courier-js": () => import("./../../../src/pages/contact/courier.js" /* webpackChunkName: "component---src-pages-contact-courier-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-shuttle-js": () => import("./../../../src/pages/contact/shuttle.js" /* webpackChunkName: "component---src-pages-contact-shuttle-js" */),
  "component---src-pages-contact-towing-js": () => import("./../../../src/pages/contact/towing.js" /* webpackChunkName: "component---src-pages-contact-towing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

